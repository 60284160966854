import Navbar from "../../comp/Navbar";
import {
  Card,
  Input,
  Typography,
  Button,
  List,
  ListItem,
} from "@material-tailwind/react";
import React from "react";
import { QrReader } from "react-qr-reader";
import { useEffect, useState } from "react";
import LoadingModal from "../../comp/LoadingModal";
import Api from "../../config/Api";
import moment from "moment";
import "moment/locale/id";
import Notif from "../../comp/Notif";

export default function Logbook() {
  const renderHTML = (encodedString) => {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    var translate = {
      nbsp: " ",
      amp: "&",
      quot: '"',
      lt: "<",
      gt: ">",
    };
    return encodedString
      .replace(translate_re, function (match, entity) {
        return translate[entity];
      })
      .replace(/&#(\d+);/gi, function (match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
      });
  };
  const [unit, setUnit] = useState(false);
  const [scan, setScan] = useState(0);
  const [errors, setErrors] = useState(false);
  const [nama, setNama] = useState("");
  const [daftarNama, setDaftarNama] = useState([]);
  const [km, setKm] = useState("");
  const [tujuan, setTujuan] = useState("");
  const [keperluan, setKeperluan] = useState("");
  const tanggal = moment().format("YYYY-MM-DD hh:mm");
  const dataUnit = localStorage.getItem("unit")
    ? JSON.parse(localStorage.getItem("unit"))
    : false;
  const _cekUnit = async () => {
    let fd = new FormData();
    fd.append("unit", unit);
    let config = {};
    await Api.post("apiv1/logbook/check_unit", fd, config)
      .then((d) => {
        if (d.data.success) {
          localStorage.setItem("unit", JSON.stringify(d.data.data));
          setKm(d.data.data.last_km);
          setScan(3);
        } else {
          setScan(0);
        }
      })
      .catch((err) => {
        Notif("error", "Ada kesalahan!");
        setScan(0);
        console.log(err);
        setErrors(JSON.stringify(err));
      });
  };
  useEffect(() => {
    if (scan === 2) {
      _cekUnit();
    }
    if (scan < 2) {
      localStorage.removeItem("unit");
      setUnit(false);
      setNama("");
      setKm("");
      setTujuan("");
      setKeperluan("");
    }
    // eslint-disable-next-line
  }, [scan]);
  const _getNama = async () => {
    let fd = new FormData();
    fd.append("suggest", nama);
    let config = {};
    await Api.post("apiv1/logbook/getNama", fd, config).then((d) => {
      if (d.data.success) {
        setDaftarNama(d.data.data);
      }
    });
  };
  useEffect(() => {
    if (nama.length === 3 || nama.length === 6 || nama.length === 9) {
      _getNama();
    }
    // eslint-disable-next-line
  }, [nama]);
  const _submit = async () => {
    setScan(9);
    let fd = new FormData();
    fd.append("nama", nama);
    fd.append("km", km);
    fd.append("tujuan", tujuan);
    fd.append("keperluan", keperluan);
    fd.append("no_lambung", dataUnit.no_lambung);
    fd.append("tanggal", tanggal);
    fd.append("last_id", dataUnit.last_id);
    fd.append("jarak_tempuh", km - dataUnit.last_km);
    let config = {};
    await Api.post("apiv1/logbook/insertLogbook", fd, config).then((d) => {
      if (d.data.success) {
        Notif("success", "Data berhasil di submit");
        setScan(0);
      } else {
        setScan(3);
        Notif("error", "Data gagal di submit. Mohon di coba lagi");
      }
    });
  };
  return (
    <div>
      <Navbar />
      <div className="Row">
        <div className="p-9">
          <Card className="w-full">
            <button className="text-center bg-ijo text-white font-bold rounded-3xl">
              {scan === 0 ? (
                "TAP TO SCAN UNIT"
              ) : scan === 1 ? (
                "SCAN QR CODE UNIT"
              ) : scan === 3 ? (
                <div>
                  <div>
                    {dataUnit.com} - {dataUnit.type}
                  </div>
                  <div>
                    <span className="text-red-100 font-thin">
                      Last HM {dataUnit.last_km} -
                    </span>
                    <span className="text-red-100 font-extrabold">
                      {" "}
                      {dataUnit.no_lambung}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </button>
            {scan === 0 ? (
              <img
                src="./assets/scan.svg"
                className="cursor-pointer hover:bg-blue-gray-50"
                onClick={() => setScan(1)}
                alt=""
              />
            ) : scan === 2 || scan === 9 ? (
              <LoadingModal isOpen={scan === 2 || scan === 9 ? true : false} />
            ) : scan === 1 ? (
              <>
                <QrReader
                  constraints={{ facingMode: "environment" }}
                  onResult={(result, error) => {
                    if (!!result) {
                      setUnit(result?.text);
                      setScan(2);
                    }

                    if (!!error) {
                      //console.info(error);
                    }
                  }}
                  style={{ width: "100%" }}
                />
                <button className="text-center bg-ijo text-white font-bold rounded-3xl">
                  Camera
                </button>
              </>
            ) : scan === 3 ? (
              <form
                className="mt-8 mb-2 max-w-screen-lg sm:w-96"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!nama || !km || !tujuan || !keperluan) {
                    Notif("warning", "Form tidak boleh ada yang kosong");
                  }
                  if (km < dataUnit.last_km) {
                    Notif("warning", "HM tidak bisa dibawah HM sebelumnya");
                  }
                  if (dataUnit.last_id && km - dataUnit.last_km > 2000) {
                    Notif("warning", "HM yang di input tidak sesuai");
                  } else {
                    _submit();
                  }
                }}
              >
                <div className="mb-1 flex flex-col gap-6">
                  <Typography variant="h6" color="blue-gray" className="-mb-5">
                    Nama Lengkap
                  </Typography>
                  <Input
                    size="lg"
                    placeholder="Your Name"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 z-9"
                    onChange={(e) => {
                      setNama(e.target.value);
                    }}
                    value={nama}
                  />
                </div>
                {daftarNama.length > 0 &&
                  nama.length > 4 &&
                  daftarNama.map((d, k) => {
                    const rNama = renderHTML(d.nama);
                    if (rNama === nama) {
                      return <></>;
                    } else {
                      return (
                        <List key={k}>
                          <ListItem
                            className="z-50 bg-blue-gray-50 absolute"
                            onClick={() => {
                              setNama(rNama);
                            }}
                          >
                            {rNama}
                          </ListItem>
                        </List>
                      );
                    }
                  })}
                <div className="mb-1 flex flex-col gap-6">
                  <Typography variant="h6" color="blue-gray" className="-mb-5">
                    KM{" "}
                    <div className="font-thin text-red-900 text-xs">
                      Note:{" "}
                      {km < dataUnit.last_km
                        ? "Tidak boleh dibawah Last HM!"
                        : "jarak yang telah ditempuh adalah " +
                          (km - dataUnit.last_km) +
                          " KM"}
                    </div>
                  </Typography>
                  <Input
                    size="lg"
                    type="number"
                    placeholder="KM"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={(e) => setKm(e.target.value)}
                    value={km}
                  />
                </div>
                <div className="mb-1 flex flex-col gap-6">
                  <Typography variant="h6" color="blue-gray" className="-mb-5">
                    Tujuan
                  </Typography>
                  <Input
                    size="lg"
                    type="text"
                    placeholder="Tujuan"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={(e) => setTujuan(e.target.value)}
                  />
                </div>
                <div className="mb-1 flex flex-col gap-6">
                  <Typography variant="h6" color="blue-gray" className="-mb-5">
                    Keperluan
                  </Typography>
                  <Input
                    size="lg"
                    type="text"
                    placeholder="Tujuan"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={(e) => setKeperluan(e.target.value)}
                  />
                </div>
                <Button className="mt-6" fullWidth type="submit">
                  Submit
                </Button>
                <Typography
                  color="gray"
                  className="mt-4 text-center font-normal"
                >
                  Pastikan form sudah di input semua dan di isi dengan benar
                </Typography>
              </form>
            ) : (
              <></>
            )}
          </Card>
          <Typography color="gray" className="mt-4 text-center font-normal">
            Logbook Online V2.0
          </Typography>
          <Typography color="gray" className="mt-4 text-center font-normal">
            {errors && errors}
          </Typography>
        </div>
      </div>
    </div>
  );
}
