import React from "react";
import {
  Dialog,
  DialogBody,
  Spinner,
  Typography,
} from "@material-tailwind/react";
export default function LoadingModal({ isOpen }) {
  return (
    <Dialog open={isOpen}>
      <DialogBody>
        <div className="py-20">
          <div className="flex justify-between">
            <Spinner color="blue" className="h-12 w-12" />
            <Spinner color="red" className="h-12 w-12" />
            <Spinner color="green" className="h-12 w-12" />
            <Spinner color="amber" className="h-12 w-12" />
            <Spinner color="teal" className="h-12 w-12" />
            <Spinner color="indigo" className="h-12 w-12" />
            <Spinner color="purple" className="h-12 w-12" />
          </div>
          <Typography variant="h5" className="text-center">
            Loading....
          </Typography>
        </div>
      </DialogBody>
    </Dialog>
  );
}
