import { Link } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
export default function Navbar() {
  return (
    <nav className="py-5 px-4">
      <div className="container mx-auto">
        <div className="flex justify-between">
          <NotificationContainer />
          <Link to={"/"}>
            <img
              color="red"
              width="150px"
              src="https://static.wixstatic.com/media/d6f5a6_26f7cc86149d4e56ab162926008fe087~mv2.png/v1/fill/w_250,h_55,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/d6f5a6_26f7cc86149d4e56ab162926008fe087~mv2.png"
              alt=""
            />
          </Link>
        </div>
      </div>
    </nav>
  );
}
