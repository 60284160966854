import { Card, Button, CardFooter, CardHeader } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";
import Notif from "../../../comp/Notif";

export default function CardHome() {
  return (
    <div className="p-1 md:p-20 grid grid-cols-2 md:grid-cols-4 overflow-hidden content-center mt-3">
      <Link to={"/logbook"}>
        <Card className="w-full shadow-lg m-2">
          <CardHeader floated={false} color="blue-gray">
            <img src={require("../../../assets/LOGBOOK.png")} alt="Logbook" />
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
          </CardHeader>

          <CardFooter className="pt-3">
            <Button size="sm" fullWidth={true}>
              LOGBOOK TRANSPORT
            </Button>
          </CardFooter>
        </Card>
      </Link>
      <Link to={"/izinkeluar"}>
        <Card className="w-full shadow-lg m-2">
          <CardHeader floated={false} color="blue-gray">
            <img src={require("../../../assets/permit.jpg")} alt="permit" />
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
          </CardHeader>

          <CardFooter className="pt-3">
            <Button size="sm" fullWidth={true}>
              OUT OF SITE PERMIT
            </Button>
          </CardFooter>
        </Card>
      </Link>
      <Card
        className="w-full shadow-lg m-2"
        onClick={() => Notif("warning", "Under Construction")}
      >
        <CardHeader floated={false} color="blue-gray">
          <img src={require("../../../assets/lv.png")} alt="permit" />
          <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
        </CardHeader>

        <CardFooter className="pt-3">
          <Button size="sm" fullWidth={true}>
            light vehicle exit permit
          </Button>
        </CardFooter>
      </Card>
      <Card
        className="w-full shadow-lg m-2"
        onClick={() => Notif("warning", "Under Construction")}
      >
        <CardHeader floated={false} color="blue-gray">
          <img src={require("../../../assets/svr.png")} alt="permit" />
          <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
        </CardHeader>

        <CardFooter className="pt-3">
          <Button size="sm" fullWidth={true}>
            SITE VISIT REQUEST
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
