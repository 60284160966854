import { Route, Routes } from "react-router-dom";
import Home from "./view/Home/Home";
import Logbook from "./view/Logbook/Logbook";
import Outsitepermit from "./view/Outsitepermit/Outsitepermit";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/logbook" element={<Logbook />} />
      <Route path="/izinkeluar" element={<Outsitepermit />} />
    </Routes>
  );
}

export default App;
