let WS_URL = null;
if (process.env.NODE_ENV === "development") {
  //WS_URL = 'http://' + window.location.hostname + ':8080/'; //WEBSERVICE URL
  WS_URL = "http://10.102.101.29/hrgait/ws/"; //WEBSERVICE URL
  //WS_URL = "http://localhost:8080/"; //WEBSERVICE URL
  // WS_URL = window.location.origin + "/hrgait/ws/"; //WEBSERVICE URL
} else {
  // WS_URL = "https://api.jrbmbakan.my.id/hrgait/ws/"; //WEBSERVICE URL
  WS_URL = "http://10.102.101.29/hrgait/ws/"; //WEBSERVICE URL
}
export { WS_URL };
