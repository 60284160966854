import CardHome from "./comp/CardHome";
import Navbar from "../../comp/Navbar";
import { NotificationContainer } from "react-notifications";

export default function Home() {
  return (
    <div>
      <Navbar />
      <NotificationContainer />
      <div className="Row">
        <CardHome />
      </div>
    </div>
  );
}
