import Navbar from "../../comp/Navbar";
import {
  Card,
  Input,
  Typography,
  Button,
  Select,
  Option,
  ButtonGroup,
  CardBody,
  Chip,
  CardFooter,
} from "@material-tailwind/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import { QrReader } from "react-qr-reader";
import { useEffect, useState } from "react";
import LoadingModal from "../../comp/LoadingModal";
import Api from "../../config/Api";
import moment from "moment";
import "moment/locale/id";
import Notif from "../../comp/Notif";

export default function Outsitepermit() {
  const TABLE_HEAD = [
    "No/Status",
    "Date in/Out",
    "Block Camp/ Tujuan",
    "Kepentingan",
    "Keterangan",
    "",
  ];
  const _getDataByCreated = async () => {
    setLoading(true);
    let fd = new FormData();
    fd.append("nik", dataEmp.nik);
    let config = {};
    await Api.post("apiv1/izinkeluar/listbycreated", fd, config)
      .then((d) => {
        if (d.data.success) {
          setDataList(d.data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        Notif("error", "Ada kesalahan!");
        setScan(0);
        setLoading(false);
        console.log(err);
        setErrors(JSON.stringify(err));
      });
  };
  const renderHTML = (encodedString) => {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    var translate = {
      nbsp: " ",
      amp: "&",
      quot: '"',
      lt: "<",
      gt: ">",
    };
    return encodedString
      .replace(translate_re, function (match, entity) {
        return translate[entity];
      })
      .replace(/&#(\d+);/gi, function (match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
      });
  };
  const [Emp, setEmp] = useState(false);
  const [scan, setScan] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [selectKepentingan, setSelectKEpentingan] = useState(false);
  const [tanggalMasuk, setTanggalMasuk] = useState("");
  const [tanggalKeluar, setTanggalKeluar] = useState("");
  const [kepentingan, setKepentingan] = useState("");
  const [blokKamar, setBloKamar] = useState("");
  const [bawaan, setBawaan] = useState("-");
  const [tujuan, setTujuan] = useState("");
  const [dataList, setDataList] = useState([]);
  const dataEmp = localStorage.getItem("Emp")
    ? JSON.parse(localStorage.getItem("Emp"))
    : false;
  const _cekEmp = async () => {
    setLoading(true);
    let fd = new FormData();
    fd.append("nik", Emp);
    let config = {};
    await Api.post("apiv1/izinkeluar/ceknik", fd, config)
      .then((d) => {
        if (d.data.success) {
          localStorage.setItem("Emp", JSON.stringify(d.data.data));
          setScan(3);
          setLoading(false);
        } else {
          setScan(0);
          setLoading(false);
        }
      })
      .catch((err) => {
        Notif("error", "Ada kesalahan!");
        setLoading(false);
        console.log(err);
        setErrors(JSON.stringify(err));
      });
  };
  useEffect(() => {
    if (scan === 2) {
      _cekEmp();
    }
    if (scan === 4) {
      _getDataByCreated();
    }
    if (scan === 0 && dataEmp) {
      setScan(3);
    }

    // eslint-disable-next-line
  }, [scan]);

  const _submit = async () => {
    setScan(9);
    setLoading(true);
    let fd = new FormData();
    fd.append("nik", dataEmp.nik);
    fd.append("kepentingan", kepentingan);
    fd.append("tanggal_keluar", tanggalKeluar);
    fd.append("tanggal_masuk", tanggalMasuk);
    fd.append("blok_kamar", blokKamar);
    fd.append("tujuan", tujuan);
    fd.append("bawaan", bawaan);
    let config = {};
    await Api.post("apiv1/izinkeluar/inizinkeluar", fd, config)
      .then((d) => {
        if (d.data.success) {
          Notif("success", "Data berhasil di submit");
          setScan(0);
          setLoading(false);
        } else {
          setScan(3);
          setLoading(false);
          Notif("error", "Data gagal di submit. Mohon di coba lagi");
        }
      })
      .catch((err) => {
        setLoading(false);
        setScan(3);
        console.log(err);
      });
  };
  return (
    <div>
      <Navbar />
      <div className="Row">
        <div className="p-3">
          {scan > 2 && (
            <ButtonGroup
              className="flex flex-row justify-center w-full"
              variant="outlined"
              fullWidth
            >
              <Button
                className={scan === 3 ? "bg-ijo text-white" : ""}
                onClick={() => setScan(3)}
              >
                FORM
              </Button>
              <Button
                className={scan === 4 ? "bg-ijo text-white" : ""}
                onClick={() => setScan(4)}
              >
                STATUS
              </Button>
            </ButtonGroup>
          )}
        </div>
        <div className="px-9">
          <Card className="w-full p-1">
            <button
              className={
                "text-center font-bold rounded-3xl " +
                (scan !== 3 ? "bg-ijo text-white" : "")
              }
            >
              {scan === 0 ? (
                "TAP TO SCAN ID CARD"
              ) : scan === 1 ? (
                "SCAN QR CODE ID CARD"
              ) : scan === 3 ? (
                <div className="mt-1">
                  <div>{dataEmp.nama}</div>
                  <div className="font-bold text-orange ">({dataEmp.nik})</div>
                </div>
              ) : (
                ""
              )}
            </button>
            {scan === 0 ? (
              <img
                src="./assets/scan.svg"
                className="cursor-pointer hover:bg-blue-gray-50"
                onClick={() => setScan(1)}
                alt=""
              />
            ) : scan === 2 || scan === 9 ? (
              <></>
            ) : scan === 1 ? (
              <>
                <QrReader
                  constraints={{ facingMode: "environment" }}
                  onResult={(result, error) => {
                    if (!!result) {
                      setEmp(result?.text);
                      setScan(2);
                    }

                    if (!!error) {
                      //console.info(error);
                    }
                  }}
                  style={{ width: "100%" }}
                />
                <button className="text-center bg-ijo text-white font-bold rounded-3xl">
                  Camera
                </button>
              </>
            ) : scan === 3 ? (
              <form
                className="mt-4 mb-2 max-w-screen-lg sm:w-96"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (
                    !dataEmp.nik ||
                    !kepentingan ||
                    !tanggalMasuk ||
                    !tanggalKeluar
                  ) {
                    Notif("warning", "Form jangan ada yang kosong");
                  } else if (
                    tanggalKeluar >= tanggalMasuk ||
                    !moment(tanggalKeluar).isValid() ||
                    !moment(tanggalMasuk).isValid() ||
                    tanggalKeluar <= moment().format("YYYY-MM-DD hh:mm")
                  ) {
                    Notif(
                      "warning",
                      "Silahkan input tanggal dan jam dengan benar"
                    );
                  } else {
                    //  Notif("success", "normal");
                    _submit();
                  }
                }}
              >
                <div className="mb-1 flex flex-col gap-6">
                  <Typography variant="h6" color="blue-gray" className="-mb-5">
                    Tanggal Keluar
                  </Typography>
                  <Input
                    size="lg"
                    type="datetime-local"
                    placeholder="Tanggal Masuk"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={(e) =>
                      setTanggalKeluar(
                        moment(e.target.value).format("YYYY-MM-DD HH:mm")
                      )
                    }
                  />
                </div>
                <div className="mb-1 flex flex-col gap-6">
                  <Typography variant="h6" color="blue-gray" className="-mb-5">
                    Tanggal Masuk
                  </Typography>
                  <Input
                    size="lg"
                    type="datetime-local"
                    placeholder="Tanggal Masuk"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={(e) =>
                      setTanggalMasuk(
                        moment(e.target.value).format("YYYY-MM-DD HH:mm")
                      )
                    }
                  />
                </div>
                <div className="mb-1 flex flex-col gap-6">
                  <Typography variant="h6" color="blue-gray" className="-mb-5">
                    Kepentingan
                  </Typography>
                  <Select
                    className="mb-0 flex flex-col gap-6"
                    onChange={(e) => {
                      if (e !== false) {
                        setKepentingan(e);
                        setSelectKEpentingan(false);
                      } else {
                        setSelectKEpentingan(true);
                        setKepentingan("");
                      }
                    }}
                    placeholder={kepentingan}
                  >
                    <Option value="Fieldbreak/Anual leave">
                      Fieldbreak/Anual leave
                    </Option>
                    <Option value="Emergency/Rumah Sakit">
                      Emergency/Rumah Sakit
                    </Option>
                    <Option value={false}>Dinas</Option>
                  </Select>
                  {selectKepentingan && (
                    <div className="mb-1 -mt-3 flex flex-col gap-6">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="-mb-5"
                      >
                        Dinas
                      </Typography>
                      <Input
                        size="lg"
                        type="text"
                        placeholder="Desc"
                        value={kepentingan}
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        onChange={(e) => setKepentingan(e.target.value)}
                      />
                    </div>
                  )}
                </div>
                <div className="mb-1 flex flex-col gap-6">
                  <Typography variant="h6" color="blue-gray" className="-mb-5">
                    Blok Kamar
                  </Typography>
                  <Input
                    size="lg"
                    type="text"
                    placeholder="Blok Kamar"
                    value={blokKamar}
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={(e) => setBloKamar(e.target.value)}
                  />
                </div>

                <div className="mb-1 flex flex-col gap-6">
                  <Typography variant="h6" color="blue-gray" className="-mb-5">
                    Tujuan
                  </Typography>
                  <Select
                    className="mb-0 flex flex-col gap-6"
                    onChange={(e) => setTujuan(e)}
                    placeholder={tujuan}
                  >
                    <Option value="Kotamobagu">Kotamobagu</Option>
                    <Option value="Bakan">Bakan</Option>
                    <Option value="Bolsel">Bolsel</Option>
                    <Option value="Manado">Manado</Option>
                    <Option value="Lainnya">Lainnya</Option>
                  </Select>
                </div>
                <div className="mb-1 flex flex-col gap-6">
                  <Typography variant="h6" color="blue-gray" className="-mb-5">
                    Keterangan
                  </Typography>
                  <Input
                    size="lg"
                    type="text"
                    placeholder="Bawaan"
                    value={bawaan}
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={(e) => setBawaan(e.target.value)}
                  />
                </div>
                <Button className="mt-6" fullWidth type="submit">
                  Submit
                </Button>
                <Typography
                  color="gray"
                  className="mt-4 text-center font-normal"
                >
                  Pastikan form sudah di input semua dan di isi dengan benar
                </Typography>
              </form>
            ) : scan === 4 ? (
              <Card className="h-full w-full">
                <CardBody className="overflow-scroll px-0">
                  <table className="w-full min-w-max table-auto text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head, index) => (
                          <th
                            key={head}
                            className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                            >
                              {head}{" "}
                              {index !== TABLE_HEAD.length - 1 && (
                                <ChevronUpDownIcon
                                  strokeWidth={2}
                                  className="h-4 w-4"
                                />
                              )}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.map(
                        (
                          {
                            no,
                            nik,
                            blok_kamar,
                            kepentingan,
                            approval,
                            tanggal_keluar,
                            tanggal_masuk,
                            tujuan,
                            keterangan,
                            nama,
                            title,
                            department,
                            nextAction,
                            created_at,
                          },
                          index
                        ) => {
                          const isLast = index === dataList.length - 1;
                          const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";

                          return (
                            <tr key={no}>
                              <td className={classes + " text-center"}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {no}
                                    </Typography>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal opacity-70"
                                    >
                                      {moment(created_at).format("lll")}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="w-full text-center">
                                  <Chip
                                    variant="ghost"
                                    size="sm"
                                    value={nextAction}
                                    color={
                                      nextAction === "REJECTED"
                                        ? "red"
                                        : nextAction === "COMPLETE"
                                        ? "green"
                                        : "blue"
                                    }
                                  />
                                </div>
                              </td>
                              {/* <td className={classes}>
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {nama}
                                  </Typography>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {nik}
                                  </Typography>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal opacity-70"
                                  >
                                    {title}
                                  </Typography>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal opacity-70"
                                  >
                                    {department}
                                  </Typography>
                                </div>
                              </td> */}
                              <td className={classes}>
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    <span className="flex flex-row">
                                      <ArrowLeftIcon className="bg-red-500 h-4 w-4" />
                                      {moment(tanggal_keluar).format("lll")}
                                    </span>
                                  </Typography>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal opacity-70"
                                  >
                                    <span className="flex flex-row">
                                      <ArrowRightIcon className="bg-green-500 h-4 w-4" />

                                      {moment(tanggal_masuk).format("lll")}
                                    </span>
                                  </Typography>
                                </div>
                              </td>
                              {/* <td className={classes}>
                               
                              </td> */}
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {blok_kamar}
                                </Typography>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {tujuan}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {kepentingan}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {keterangan}
                                </Typography>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </CardBody>
                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    Page 1 of 10
                  </Typography>
                  <div className="flex gap-2">
                    <Button variant="outlined" size="sm">
                      Previous
                    </Button>
                    <Button variant="outlined" size="sm">
                      Next
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            ) : (
              <></>
            )}
          </Card>
          <LoadingModal isOpen={loading} />
          <Typography color="gray" className="mt-4 text-center font-normal">
            Out Site Permit
          </Typography>
          {/* <Typography color="gray" className="mt-4 text-center font-normal">
            {errors && errors}
          </Typography> */}
        </div>
      </div>
    </div>
  );
}
