import { NotificationManager } from "react-notifications";
export default function Notif(type, m) {
  if (type === "info") {
    NotificationManager.info(m, "Berhasil");
  }
  if (type === "success") {
    NotificationManager.success(m);
  }
  if (type === "warning") {
    NotificationManager.warning(m);
  }
  if (type === "error") {
    NotificationManager.error(m, "Error!");
  }
}
